import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

const app = initializeApp({
  apiKey: "AIzaSyCCGw4poTLV7kOGQgSX0yafQsrkh4488Sk",
  authDomain: "oogado-1711e.firebaseapp.com",
  databaseURL: "https://oogado-1711e.firebaseio.com",
  projectId: "oogado-1711e",
  storageBucket: "oogado-1711e.appspot.com",
  messagingSenderId: "70895553474",
  appId: "1:70895553474:web:44c12f82b1d6600d4ae45b",
  measurementId: "G-418BTGTE0W",
});
getAnalytics(app);
getPerformance(app);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
