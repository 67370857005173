


















import Vue from "vue";
import axios from "axios";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";

export default Vue.extend({
  name: "App",

  data: (): {
    headers: unknown[];
    items: { description: string; key: string; it?: string; en?: string }[];
    loading: boolean;
  } => ({
    headers: [
      {
        text: "Descrizione",
        sortable: false,
        value: "description",
      },
      { text: "Italiano ", sortable: false, value: "it" },
      { text: "English", sortable: false, value: "en" },
    ],
    items: [],
    loading: true,
  }),

  async created() {
    const storage = getStorage();
    const listRef = ref(storage, "I10n");

    try {
      const res = await listAll(listRef);

      const urls = await Promise.all(
        res.items.map((item) => getDownloadURL(item))
      );

      const enIndex = urls.findIndex((url) => url.match("app_en"));
      const itIndex = urls.findIndex((url) => url.match("app_it"));

      const filesRes = await Promise.all(urls.map((url) => axios.get(url)));

      this.items = Object.keys(filesRes[0].data)
        .filter((key) => key.startsWith("@"))
        .map((key) => ({
          key: key.replace("@", ""),
          description: (filesRes[0].data[key] as { description: string })
            .description,
        }));

      Object.values(this.items).forEach((item, i) => {
        const en = filesRes[enIndex].data[item.key];
        const it = filesRes[itIndex].data[item.key];

        this.items[i] = { ...this.items[i], it, en };
      });

      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
});
